import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan, getKeyYoutube } from "../utils/utilities";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

// =========================================================================

const getAutorregulacionDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AUTORREGULACIONDOCUMENTOS_SUCCESS,
      autorregulacion: []
    };
  }
  return {
    type: actionTypes.FETCH_AUTORREGULACIONDOCUMENTOS_SUCCESS,
    autorregulacion: data
  };
};

export const initAutorregulacionDocumentos = () => {
  let url = `${dominio}/cms/o/documents/autoregulation/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getAutorregulacionDocumentos(response.data));
      })
      .catch(err => dispatch(getAutorregulacionDocumentos(err.data)));
  };
};

// =========================================================================

const getLeyesNormatividadDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_NORMATIVIDADLEYESDOCUMENTOS_SUCCESS,
      leyes: null
    };
  }
  return {
    type: actionTypes.FETCH_NORMATIVIDADLEYESDOCUMENTOS_SUCCESS,
    leyes: data
  };
};

export const initLeyesNormatividadDocumentos = () => {
  let url = `${dominio}/cms/o/documents/laws/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getLeyesNormatividadDocumentos(response.data));
      })
      .catch(err => dispatch(getLeyesNormatividadDocumentos(err.data)));
  };
};

// =========================================================================

const getDisposicionesNormatividadDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_NORMATIVIDADDISPOSICIONESDOCUMENTOS_SUCCESS,
      disposiciones: null
    };
  }
  return {
    type: actionTypes.FETCH_NORMATIVIDADDISPOSICIONESDOCUMENTOS_SUCCESS,
    disposiciones: data
  };
};

export const initDisposicionesNormatividadDocumentos = () => {
  let url = `${dominio}/cms/o/documents/secondary-regulation/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getDisposicionesNormatividadDocumentos(response.data));
      })
      .catch(err => dispatch(getDisposicionesNormatividadDocumentos(err.data)));
  };
};

// =========================================================================

const getAboutDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ABOUTDOCUMENTOS_SUCCESS,
      about_us: null
    };
  }
  return {
    type: actionTypes.FETCH_ABOUTDOCUMENTOS_SUCCESS,
    about_us: data
  };
};

export const initAboutDocumentos = () => {
  let url = `${dominio}/cms/o/documents/about-us/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getAboutDocumentos(response.data));
      })
      .catch(err => dispatch(getAboutDocumentos(err.data)));
  };
};

// =========================================================================

const getPrensaDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PRENSADOCUMENTOS_SUCCESS,
      prensa: null
    };
  }
  return {
    type: actionTypes.FETCH_PRENSADOCUMENTOS_SUCCESS,
    prensa: data
  };
};

export const initPrensaDocumentos = () => {
  let url = `${dominio}/cms/o/documents/pressnews/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getPrensaDocumentos(response.data));
      })
      .catch(err => dispatch(getPrensaDocumentos(err.data)));
  };
};

// =========================================================================

const getAuditorioDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AUDITORIODOCUMENTOS_SUCCESS,
      auditorio_imagenes: null
    };
  }
  return {
    type: actionTypes.FETCH_AUDITORIODOCUMENTOS_SUCCESS,
    auditorio_imagenes: data
  };
};

export const initAuditorioDocumentos = () => {
  let url = `${dominio}/cms/o/documents/auditorium/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getAuditorioDocumentos(response.data));
      })
      .catch(err => dispatch(getAuditorioDocumentos(err.data)));
  };
};

// =========================================================================

const getAuditorioVideosDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AUDITORIOVIDEOS_SUCCESS,
      auditorio_videos: null
    };
  }
  return {
    type: actionTypes.FETCH_AUDITORIOVIDEOS_SUCCESS,
    auditorio_videos: data
  };
};

export const initAuditorioVideosDocumentos = () => {
  let url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=50&playlistId=PL6qQudeqow2RUm870uwBl9QTL-W0DaWLt&key=${getKeyYoutube()}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getAuditorioVideosDocumentos(response.data));
      })
      .catch(err => dispatch(getAuditorioVideosDocumentos(err.data)));
  };
};

// =========================================================================

const getAlianzasDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ALIANZASDOCUMENTOS_SUCCESS,
      alianzas: null
    };
  }
  return {
    type: actionTypes.FETCH_ALIANZASDOCUMENTOS_SUCCESS,
    alianzas: data
  };
};

export const initAlianzasDocumentos = () => {
  let url = `${dominio}/cms/o/documents/alliances/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getAlianzasDocumentos(response.data));
      })
      .catch(err => dispatch(getAlianzasDocumentos(err.data)));
  };
};
// =========================================================================

const getMetodologiasDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_METODOLOGIASDOCUMENTOS_SUCCESS,
      metodologias: null
    };
  }
  return {
    type: actionTypes.FETCH_METODOLOGIASDOCUMENTOS_SUCCESS,
    metodologias: data
  };
};

export const initMetodologiasDocumentos = () => {
  let url = `${dominio}/cms/o/documents/index-methodology/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getMetodologiasDocumentos(response.data));
      })
      .catch(err => dispatch(getMetodologiasDocumentos(err.data)));
  };
};

// =========================================================================

const getUltimoDiaDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMODIADOCUMENTOS_SUCCESS,
      ultimoDia: null
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMODIADOCUMENTOS_SUCCESS,
    ultimoDia: data
  };
};

export const initUltimoDiaDocumentos = () => {
  let url = `${dominio}/cms/o/documents/last-day/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getUltimoDiaDocumentos(response.data));
      })
      .catch(err => dispatch(getUltimoDiaDocumentos(err.data)));
  };
};

// =========================================================================

const getUltimoMesDocumento = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESDOCUMENTOS_SUCCESS,
      ultimoMes: null
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESDOCUMENTOS_SUCCESS,
    ultimoMes: data
  };
};

export const initUltimoMesDocumento = () => {
  let url = `${dominio}/cms/o/documents/last-month/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getUltimoMesDocumento(response.data));
      })
      .catch(err => dispatch(getUltimoMesDocumento(err.data)));
  };
};

// =========================================================================

const getOperacionDiariaDocumento = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_OPERACIONDIARIADOCUMENTOS_SUCCESS,
      operacionDiaria: null
    };
  }
  return {
    type: actionTypes.FETCH_OPERACIONDIARIADOCUMENTOS_SUCCESS,
    operacionDiaria: data
  };
};

export const initOperacionDiariaDocumento = () => {
  let url = `${dominio}/cms/o/documents/daily-operation/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getOperacionDiariaDocumento(response.data));
      })
      .catch(err => dispatch(getOperacionDiariaDocumento(err.data)));
  };
};

// =========================================================================

const getCuotasDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      cuotas: null
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    cuotas: data
  };
};

export const initCuotasDocumentos = () => {
  let url = `${dominio}/cms/o/documents/fees/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCuotasDocumentos(response.data));
      })
      .catch(err => dispatch(getCuotasDocumentos(err.data)));
  };
};

// =========================================================================

// =========================================================================

const getFichaTenica = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      fichaTecnica: null
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    fichaTecnica: data
  };
};

export const initFichaTecnicaDocumento = () => {
  let url = `${dominio}/cms/o/documents/data-sheet/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getFichaTenica(response.data));
      })
      .catch(err => dispatch(getFichaTenica(err.data)));
  };
};

// =========================================================================
// =========================================================================

const getInstituto = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      instituto: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    instituto: data
  };
};

export const initDocumentosInstitutoBiva = () => {
  let url = `${dominio}/cms/o/documents/convocatoria-inst-biva/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getInstituto(response.data));
      })
      .catch(err => dispatch(getInstituto(err.data)));
  };
};

// =========================================================================

const getEmbajadoresBiva = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      embajadores: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    embajadores: data
  };
};

export const getEmbajadoresBivaFotos = () => {
  let url = `${dominio}/cms/o/documents/ib/embajadores/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getEmbajadoresBiva(response.data));
      })
      .catch(err => dispatch(getEmbajadoresBiva(err.data)));
  };
};

// =========================================================================

const setCursosPresenciales = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      cursosPresenciales: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    cursosPresenciales: data
  };
};

export const getCursosPresenciales = () => {
  let url = `${dominio}/cms/o/documents/ib-cursos-presenciales/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCursosPresenciales(response.data));
      })
      .catch(err => dispatch(setCursosPresenciales(err.data)));
  };
};

// =========================================================================

const setCursosRemotos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      cursosRemotos: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    cursosRemotos: data
  };
};

export const getCursosRemotos = () => {
  let url = `${dominio}/cms/o/documents/ib-cursos-remotos/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCursosRemotos(response.data));
      })
      .catch(err => dispatch(setCursosRemotos(err.data)));
  };
};

// =========================================================================

//Documentos instituto Biva
// =========================================================================

const setArticulosAcademicos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      articulosAcademicos: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    articulosAcademicos: data
  };
};

export const getArticulosAcademicos = (keyword = null) => {
  let params = {
    keyword: keyword === "" ? null : keyword
  };
  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/cms/o/documents/ib-articulos-academicos/lang/${lang}`;

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setArticulosAcademicos(response.data));
      })
      .catch(err => dispatch(setArticulosAcademicos(err.data)));
  };
};
// =========================================================================

const setRevistas = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      revistas: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    revistas: data
  };
};

export const getRevistas = (keyword = null) => {
  let params = {
    keyword: keyword === "" ? null : keyword
  };
  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/cms/o/documents/ib-revistas/lang/${lang}`;

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setRevistas(response.data));
      })
      .catch(err => dispatch(setRevistas(err.data)));
  };
};

// =========================================================================

const setEscritos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      escritos: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    escritos: data
  };
};

export const getEscritos = (keyword = null) => {
  let params = {
    keyword: keyword === "" ? null : keyword
  };
  const queryString = StringUtils.jsonToQueryString(params);

  let url = `${dominio}/cms/o/documents/ib-escritos/lang/${lang}`;

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setEscritos(response.data));
      })
      .catch(err => dispatch(setEscritos(err.data)));
  };
};
// =========================================================================

const setBlog = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      blog: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    blog: data
  };
};

export const getBlogs = (keyword = null) => {
  let params = {
    keyword: keyword === "" ? null : keyword
  };
  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/cms/o/documents/ib-blog/lang/${lang}`;

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setBlog(response.data));
      })
      .catch(err => dispatch(setBlog(err.data)));
  };
};
// =========================================================================

const setLibros = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      libros: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    libros: data
  };
};

export const getLibros = (keyword = null) => {
  let params = {
    keyword: keyword === "" ? null : keyword
  };
  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/cms/o/documents/ib-libros/lang/${lang}`;

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setLibros(response.data));
      })
      .catch(err => dispatch(setLibros(err.data)));
  };
};
// =========================================================================

const setPreguntasFrecuentes = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      preguntasFrecuentes: null
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    preguntasFrecuentes: data
  };
};

export const initPreguntasFrecuentes = () => {
  let url = `${dominio}/cms/o/documents/faq/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPreguntasFrecuentes(response.data));
      })
      .catch(err => dispatch(setPreguntasFrecuentes(err.data)));
  };
};
// =========================================================================

const setDiccionarios = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      diccionarios: []
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    diccionarios: data
  };
};

export const getDiccionarios = (keyword = null) => {
  let params = {
    keyword: keyword === "" ? null : keyword
  };
  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/cms/o/documents/ib-diccionarios/lang/${lang}`;

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setDiccionarios(response.data));
      })
      .catch(err => dispatch(setDiccionarios(err.data)));
  };
};
// =========================================================================

const setNuestroEquipo = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      equipo: null
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    equipo: data
  };
};

export const getNuestroEquipo = () => {
  let url = `${dominio}/cms/o/documents/nosotros/equipo/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setNuestroEquipo(response.data));
      })
      .catch(err => dispatch(setNuestroEquipo(err.data)));
  };
};
// =========================================================================

const setSolicitud = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      solicitudMiembroBiva: null
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    solicitudMiembroBiva: data
  };
};

export const getSolicitudMiembroBiva = () => {
  let url = `${dominio}/cms/o/documents/solicitud-cb-miembro/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSolicitud(response.data));
      })
      .catch(err => dispatch(setSolicitud(err.data)));
  };
};
// =========================================================================

const setSolicitudFormadorMercado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUOTAS_SUCCESS,
      solicitudFormadorMercado: null
    };
  }
  return {
    type: actionTypes.FETCH_CUOTAS_SUCCESS,
    solicitudFormadorMercado: data
  };
};

export const getSolicitudFormadorMercado = () => {
  let url = `${dominio}/cms/o/documents/solicitud-formador-mercado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSolicitudFormadorMercado(response.data));
      })
      .catch(err => dispatch(setSolicitudFormadorMercado(err.data)));
  };
};

// =========================================================================

const setManualOperativo = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      manualReglamento: null
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    manualReglamento: data
  };
};

export const getManualReglamento = () => {
  let url = `${dominio}/cms/o/documents/internal-regulation-and-operations-manual/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setManualOperativo(response.data));
      })
      .catch(err => dispatch(setManualOperativo(err.data)));
  };
};
// =========================================================================

const setLineamientosCertificacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      lineamientos: null
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    lineamientos: data
  };
};

export const getLineamientosCertificacion = () => {
  let url = `${dominio}/cms/o/documents/tech-vendors-cert-docs/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setLineamientosCertificacion(response.data));
      })
      .catch(err => dispatch(setLineamientosCertificacion(err.data)));
  };
};
// =========================================================================

const setDocMarketPhase = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      marketPhase: null
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    marketPhase: data
  };
};

export const getDocMarketPhase = () => {
  let url = `${dominio}/cms/o/documents/docs-market-phases/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocMarketPhase(response.data));
      })
      .catch(err => dispatch(setDocMarketPhase(err.data)));
  };
};

// =========================================================================

const setEnlacesConectividad = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesConectividad: null
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesConectividad: data
  };
};

export const getEnlacesConectividad = () => {
  let url = `${dominio}/cms/o/documents/lineamientos-cert/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesConectividad(response.data));
      })
      .catch(err => dispatch(setEnlacesConectividad(err.data)));
  };
};
// =========================================================================

const setEnlacesContratarItch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesContratarItch: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesContratarItch: data
  };
};

export const getEnlacesContratarItch = () => {
  let url = `${dominio}/cms/o/documents/contrato-itch/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesContratarItch(response.data));
      })
      .catch(err => dispatch(setEnlacesContratarItch(err.data)));
  };
};
// =========================================================================

const setEnlacesContratarBim = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesContratarBim: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesContratarBim: data
  };
};

export const getEnlacesContratarBim = () => {
  let url = `${dominio}/cms/o/documents/contratar-bim/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesContratarBim(response.data));
      })
      .catch(err => dispatch(setEnlacesContratarBim(err.data)));
  };
};
// =========================================================================

const setEnlacesReporteEspecializado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesReporteEspecializado: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesReporteEspecializado: data
  };
};

export const getEnlacesReporteEspecializado = () => {
  let url = `${dominio}/cms/o/documents/reporte-especializado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesReporteEspecializado(response.data));
      })
      .catch(err => dispatch(setEnlacesReporteEspecializado(err.data)));
  };
};

// =========================================================================

const setEnlacesEspecificacionesItch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesEspecificacionesItch: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesEspecificacionesItch: data
  };
};

export const getEnlacesEspecificacionesItch = () => {
  let url = `${dominio}/cms/o/documents/especificaciones-itch/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesEspecificacionesItch(response.data));
      })
      .catch(err => dispatch(setEnlacesEspecificacionesItch(err.data)));
  };
};

// =========================================================================

const setEnlacesGuiaProcesoListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesGuiaProcesoListado: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesGuiaProcesoListado: data
  };
};

export const getEnlacesGuiaProcesoListado = () => {
  let url = `${dominio}/cms/o/documents/guia-proceso-listado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesGuiaProcesoListado(response.data));
      })
      .catch(err => dispatch(setEnlacesGuiaProcesoListado(err.data)));
  };
};
// =========================================================================

const setEnlacesArchivosProcesoListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesArchivosProcesoListado: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesArchivosProcesoListado: data
  };
};

export const getEnlacesArchivosProcesoListado = () => {
  let url = `${dominio}/cms/o/documents/archivos-proceso-listado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesArchivosProcesoListado(response.data));
      })
      .catch(err => dispatch(setEnlacesArchivosProcesoListado(err.data)));
  };
};
// =========================================================================

const setEnlacesArchivosCambioListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesArchivosCambioListado: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesArchivosCambioListado: data
  };
};

export const getEnlacesArchivosCambioListado = () => {
  let url = `${dominio}/cms/o/documents/archivos-cambio-listado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesArchivosCambioListado(response.data));
      })
      .catch(err => dispatch(setEnlacesArchivosCambioListado(err.data)));
  };
};
// =========================================================================

const setEnlacesCuotaListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesCuotaListado: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesCuotaListado: data
  };
};

export const getEnlacesCuotaListado = () => {
  let url = `${dominio}/cms/o/documents/cuotas-listado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesCuotaListado(response.data));
      })
      .catch(err => dispatch(setEnlacesCuotaListado(err.data)));
  };
};
// =========================================================================

const setEnlacesListadoFsCap = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesListadoFsCap: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesListadoFsCap: data
  };
};

export const getEnlacesListadoFsCap = () => {
  let url = `${dominio}/cms/o/documents/listado-fs-cap/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesListadoFsCap(response.data));
      })
      .catch(err => dispatch(setEnlacesListadoFsCap(err.data)));
  };
};
// =========================================================================

const setEnlacesListadoFsDeuda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesListadoFsDeuda: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesListadoFsDeuda: data
  };
};

export const getEnlacesListadoFsDeuda = () => {
  let url = `${dominio}/cms/o/documents/listado-fs-deuda/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesListadoFsDeuda(response.data));
      })
      .catch(err => dispatch(setEnlacesListadoFsDeuda(err.data)));
  };
};

// =========================================================================

const setEnlacesListadoFsSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      enlacesListadoFsSic: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    enlacesListadoFsSic: data
  };
};

export const getEnlacesListadoFsSic = () => {
  let url = `${dominio}/cms/o/documents/listado-fs-sic/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlacesListadoFsSic(response.data));
      })
      .catch(err => dispatch(setEnlacesListadoFsSic(err.data)));
  };
};

// =========================================================================

const setDataSheetInformacionGeneral = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      dataSheet: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    dataSheet: data
  };
};

export const getDataSheetInformacionGeneral = () => {
  let url = `${dominio}/cms/o/documents/data-sheet/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDataSheetInformacionGeneral(response.data));
      })
      .catch(err => dispatch(setDataSheetInformacionGeneral(err.data)));
  };
};

// =========================================================================

const setDocumentosInfoGeneral = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      infoGeneral: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
    infoGeneral: data
  };
};

export const getDocumentosInfoGeneral = () => {
  let url = `${dominio}/cms/o/documents/docs-info-general-indices/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocumentosInfoGeneral(response.data));
      })
      .catch(err => dispatch(setDocumentosInfoGeneral(err.data)));
  };
};

// =========================================================================

const setDocumentosQueEstaPasando = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
        queEstaPasando: []
      };
    }
    return {
      type: actionTypes.FETCH_DOCUMENTOS_ENLACES,
      queEstaPasando: data
    };
  };
  
  export const getDocumentosQueEstaPasando = () => {
    let url = `${dominio}/cms/o/documents/embajador-documentos/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setDocumentosQueEstaPasando(response.data));
        })
        .catch(err => dispatch(setDocumentosQueEstaPasando(err.data)));
    };
  };